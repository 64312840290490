import { useEffect } from "react";

export function useFeedCardObservers(
  cardRefs,
  setCurrFeedCardInex,
  currFeedCardIndexRef,
  isObserverPausedRef,
) {
  useEffect(() => {
    const observers = cardRefs?.current.map((ref, index) => {
      if (!ref.current) return undefined;

      const observer = new IntersectionObserver(
        ([entry]) => {
          if (
            !isObserverPausedRef.current &&
            entry.isIntersecting &&
            entry.intersectionRatio > 0.5
          ) {
            setCurrFeedCardInex(index + 1);
            currFeedCardIndexRef.current = index + 1;
            console.log("Observer index:", currFeedCardIndexRef.current);
          }
        },
        { threshold: [0.5] },
      );

      observer.observe(ref.current);
      return observer;
    });

    return () =>
      observers?.forEach((observer) => observer && observer.disconnect());
  }, [cardRefs, setCurrFeedCardInex]);
}
