import React from "react";
import { useSelector, useDispatch } from "react-redux";
import PlaylistDropdown from "../PlaylistDropdown/PlaylistDropdown";
import MoreDropdown from "../MoreDropdown/MoreDropdown.js";
import ShareDropdown from "../ShareDropdown/ShareDropdown.js";
import { showSignup } from "../../actions/global.action";
import "./styles.scss";

import heartBlack from "../../assets/heart-black.svg";
import heartRed from "../../assets/heart-red.svg";

// import newNotesBlack from "../../assets/new-notes-black.svg";
// import newNotesWhite from "../../assets/new-notes-white.svg";

//Old icons:
import upNextBlack from "../../assets/up-next-black.svg";
import upNextWhite from "../../assets/up-next-white.svg";
import notesBlack from "../../assets/notes-black.svg";
import notesWhite from "../../assets/notes-white.svg";

// Alternative icons:
// import newNotes1Black from "../../assets/new-notes-1-black.svg";
// import newNotes2Black from "../../assets/new-notes-1-black.svg";

//Not used icons anymore
// import newUpNextBlack from "../../assets/new-up-next-black.svg";
// import newUpNextWhite from "../../assets/new-up-next-white.svg";

const Sidebar = ({
  type,
  video,
  horizontal = false,
  selected,
  setSelectedOption,
  setIsDetailMode,
  handleLike,
  isLiked,
  likeCount,
}) => {
  /*
  Right sidebar when watching a video.
  Functionality:
    - Add/Remove from favourites
    - Add note
    - Add to playlist
    - Share [TBD]
*/
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const handleNote = () => {
    if (!isAuthenticated) {
      dispatch(showSignup(true));
      return;
    }
    setIsDetailMode(true);
    setSelectedOption("notes");
  };

  const handlePlaylist = () => {
    if (!isAuthenticated) {
      dispatch(showSignup(true));
      return;
    }
    setIsDetailMode(true);
    setSelectedOption(
      window.location.pathname.includes("/playlist/")
        ? "playlist"
        : type === "video" || type === "card"
          ? "upNext"
          : "recommended",
    );
  };

  return (
    <div className="sidebar-wrapper">
      <div className={`sidebar-menu ${horizontal ? "horizontal" : "vertical"}`}>
        <div className="label-option">
          <button onClick={handleLike} className="sidebar-button">
            <img src={isLiked ? heartRed : heartBlack} alt="Heart" />
          </button>
          <div>{<p className="sidebar-text">{likeCount}</p>}</div>
        </div>
        <PlaylistDropdown
          horizontal={horizontal}
          className="sidebar-button"
          itemId={video.id}
          addFunction={type === "card" ? "card" : "video"}
          isDashboard={false}
        />
        <div className="label-option">
          <button
            className={`sidebar-button ${selected === "notes" && "btn-selected"}`}
            onClick={handleNote}
          >
            <img
              src={selected === "notes" && horizontal ? notesWhite : notesBlack}
              alt="Notes"
            />
          </button>
          <p className="sidebar-text">Note</p>
        </div>
        <div className="label-option">
          <button
            className={`sidebar-button ${
              (selected === "playlist" ||
                selected === "upNext" ||
                selected === "recommended") &&
              "btn-selected"
            }`}
            onClick={handlePlaylist}
          >
            {(selected === "playlist" ||
              selected === "upNext" ||
              selected === "recommended") && (
              <img src={upNextWhite} alt="Up next videos" />
            )}
            {selected !== "upNext" &&
              selected !== "playlist" &&
              selected !== "recommended" && (
                <img src={upNextBlack} alt="Up next videos" />
              )}
          </button>
          <p className="sidebar-text">
            {window.location.pathname.includes("/playlist/")
              ? "Playlist"
              : type === "video" || type === "card"
                ? "Up Next"
                : "Recs"}
          </p>
        </div>
        <ShareDropdown
          type={type === "card" ? "card" : "video"}
          idToShare={video.id}
          horizontal={horizontal}
          className="sidebar-button"
        />
        <MoreDropdown
          videoId={video.id}
          horizontal={horizontal}
          className="sidebar-button"
        />
      </div>
    </div>
  );
};

export default Sidebar;
