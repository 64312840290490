import { IToken, LoginData, RegisterData, User } from "../interface/auth.ts";
import { CommonService } from "../interface/services.ts";
import { Cookie } from "../utils/cookie.ts";
import { ApiService } from "./api.service.ts";
import { AUTH_TOKEN } from "../utils/enum.ts";

// interface IAdminRole {
// 	_id: string;
// 	code: string;
// 	name: string;
// }

class AuthService implements CommonService<any> {
  static instance: AuthService;
  isLoggedIn: boolean = false;

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  constructor() {
    if (AuthService.instance) {
      throw new Error(
        "Error: Instantiation failed: Use AuthService.getInstance() instead of new.",
      );
    }
    AuthService.instance = this;
  }

  saveToken(token: IToken) {
    Cookie.Set(AUTH_TOKEN.ACCESS_TOKEN, token.access_token);
    Cookie.Set(AUTH_TOKEN.REFRESH_TOKEN, token.refresh_token);

    localStorage.setItem("access", token.access_token);
    localStorage.setItem("refresh", token.refresh_token);
  }

  // saveAdminData(role: IAdminRole, menu: any[]) {
  //   const adminData = { role, menu }
  //   localStorage.setItem('admin_data', JSON.stringify(adminData))
  // }

  isAuthenticated() {
    if (this.isLoggedIn) {
      return true;
    } else if (
      !!Cookie.Get(AUTH_TOKEN.ACCESS_TOKEN) ||
      !!Cookie.Get(AUTH_TOKEN.REFRESH_TOKEN) ||
      !!localStorage.getItem("access") ||
      !!localStorage.getItem("refresh")
    ) {
      return true;
    } else {
      return false;
    }
  }

  async login(data: LoginData): Promise<any> {
    try {
      const response = await ApiService.post("/auth/login", data);
      if (response) {
        // this.saveAdminData(response.data.role, response.data.menu)
        this.saveToken({
          access_token: response.access_token,
          refresh_token: response.refresh_token,
        });

        this.isLoggedIn = true;
        localStorage.setItem("access", response.access_token);
        localStorage.setItem("refresh", response.refresh_token);

        // Cookie.Set(AUTH_TOKEN.ACCESS_TOKEN, response.data.access)
        // Cookie.Set(AUTH_TOKEN.REFRESH_TOKEN, response.data.refresh)
      }
      return response;
    } catch (error) {
      this.isLoggedIn = false;
      return Promise.reject(error);
    }
  }

  async loadUser(): Promise<any> {
    const isAuthenticated = this.isAuthenticated();
    return isAuthenticated && (await ApiService.get("/auth/users/me/"));
  }

  async checkJwtToken(accessToken: string): Promise<any> {
    return await ApiService.post("/auth/jwt/verify/", {
      token: accessToken,
    });
  }

  async refreshToken(refreshToken: string): Promise<any> {
    const response = await ApiService.post("/auth/token/refresh/", {
      refresh_token: refreshToken,
    });
    if (response.access_token) {
      Cookie.Set(AUTH_TOKEN.ACCESS_TOKEN, response.access_token);
      localStorage.setItem("access", response.access_token);
      return response;
    } else {
      this.logout();
    }
  }

  profile(): Promise<User> {
    return ApiService.get("/getProfile");
  }

  async logout(): Promise<boolean> {
    try {
      // return ApiService.get('/auth/profile').then((data) => {
      //   if (data) {
      Cookie.Remove(AUTH_TOKEN.ACCESS_TOKEN);
      Cookie.Remove(AUTH_TOKEN.REFRESH_TOKEN);
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      // localStorage.removeItem('admin_data')
      return true;
      //   }
      //   return !!data
      // })
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async register(data: RegisterData): Promise<any> {
    try {
      const response = await ApiService.post("/auth/sign_up", data);
      if (response) {
        // this.saveAdminData(response.data.role, response.data.menu)
        this.saveToken({
          access_token: response.access_token,
          refresh_token: response.refresh_token,
        });
        this.isLoggedIn = true;
        // Cookie.Set(AUTH_TOKEN.ACCESS_TOKEN, response.data.access)
        // Cookie.Set(AUTH_TOKEN.REFRESH_TOKEN, response.data.refresh)
      }
      return response;
    } catch (error) {
      this.isLoggedIn = false;
      return Promise.reject(error);
    }
  }

  async activateAccount(uid: string): Promise<any> {
    return ApiService.post("/auth/users/activation/", uid);
  }

  async deleteAccount(): Promise<any> {
    const response = await ApiService.delete("/user/delete/");

    Cookie.Remove(AUTH_TOKEN.ACCESS_TOKEN);
    Cookie.Remove(AUTH_TOKEN.REFRESH_TOKEN);
    localStorage.removeItem("access");
    localStorage.removeItem("refresh");

    return response;
  }

  async resetPassword(email: string): Promise<any> {
    return ApiService.post("/auth/users/reset_password/", { email });
  }

  async social_login(code: string, connection: string): Promise<any> {
    const response = await ApiService.post("/auth/social", {
      code,
      connection,
      redirectUri: `${window.location.origin}/social-auth?connection=${connection}`,
    });

    if (response) {
      this.saveToken({
        access_token: response.access_token,
        refresh_token: response.refresh_token,
      });
      this.isLoggedIn = true;
      localStorage.setItem("access", response.access_token);
      localStorage.setItem("refresh", response.refresh_token);
    }
    return response;
  }

  async get_social_code(connection: string): Promise<any> {
    const authConfig = {
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: `${window.location.origin}/social-auth?connection=${connection}&next=${window.location.pathname}`,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE, // Optional: if you have an API to call
    };

    const authParams = {
      response_type: "code",
      client_id: authConfig.clientId,
      redirect_uri: authConfig.redirectUri,
      scope: "offline_access openid profile email user_metadata",
      connection: connection,
      audience: authConfig.audience,
    };
    window.location.href = `https://${authConfig.domain}/authorize?${new URLSearchParams(authParams)}`;
  }
}

const authService = AuthService.getInstance();

localStorage.setItem("access", Cookie.Get(AUTH_TOKEN.ACCESS_TOKEN));
localStorage.setItem("refresh", Cookie.Get(AUTH_TOKEN.REFRESH_TOKEN));

export default authService;
