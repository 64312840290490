import {
  ADD_LIKE,
  REMOVE_LIKE,
  SET_DATA,
  SET_USER_VIDEO_DATA,
  UPDATE_USER_VIDEO_DATA,
  SUBSCRIBE,
  UNSUBSCRIBE,
  UPDATE_NOTE_CONTENT,
  SET_WATCH_HISTORY,
  UPDATE_WATCH_HISTORY,
  SET_FAVORITES,
  UPDATE_FAVORITES,
  SET_NOTE_LIST,
  UPDATE_NOTE_LIST,
} from "./types";

export const setWatchHistory = (data) => ({
  type: SET_WATCH_HISTORY,
  payload: data,
});

export const updateWatchHistory = () => ({
  type: UPDATE_WATCH_HISTORY,
});

export const setFavorites = (data, categories) => ({
  type: SET_FAVORITES,
  payload: { data, categories },
});

export const updateFavorites = () => ({
  type: UPDATE_FAVORITES,
});

export const setNoteList = (data) => ({
  type: SET_NOTE_LIST,
  payload: data,
});

export const updateNoteList = () => ({
  type: UPDATE_NOTE_LIST,
});

export const setData = (data) => ({
  type: SET_DATA,
  payload: data,
});

export const setUserVideoData = (data) => ({
  type: SET_USER_VIDEO_DATA,
  payload: data,
});

export const updateUserVideoData = () => ({
  type: UPDATE_USER_VIDEO_DATA,
});

export const updateNoteContent = (id, note) => {
  return {
    type: UPDATE_NOTE_CONTENT,
    payload: { id, note },
  };
};

export const addLike = (id) => {
  return {
    type: ADD_LIKE,
    payload: id,
  };
};

export const removeLike = (id) => {
  return {
    type: REMOVE_LIKE,
    payload: id,
  };
};

export const subscribeEvent = (id) => {
  return {
    type: SUBSCRIBE,
    payload: id,
  };
};

export const unsubscribeEvent = (id) => {
  return {
    type: UNSUBSCRIBE,
    payload: id,
  };
};
