import React, { memo, useRef, useEffect } from "react";
import ItemCard from "../ItemCard/ItemCard.js";
import "./styles.scss";

const Playlist = memo(
  ({ type, navigateToItem, playlistItems, playlistTitle, index }) => {
    /*
    Component that shows a list of videos to be watched next. Used by the FeedCard (/feed, /video/uuid, /playlist/uuid).
  */

    const titleMapping = {
      playlist: playlistTitle,
      upNext: "Up Next",
      recommended: "Recommended",
    };

    const title = titleMapping[type] || "Default";

    const showCountMapping = {
      playlist: true,
      upNext: true,
      recommended: false,
    };

    const showCount = showCountMapping[type];

    const highlightedRef = useRef(null);

    // Scrolls to the ItemCard on mount
    useEffect(() => {
      if (highlightedRef.current && type !== "recommended") {
        highlightedRef.current.scrollIntoView({
          inline: "center",
          block: "center",
        });
      }
    }, []);

    return (
      <div className="playlist-preview-component">
        <div className="playlist-header">
          <div className="playlist-header-title">{title}</div>
          {showCount && (
            <>
              <p>
                {/* {index} directly because each FeedCard has its own Playlist */}
                {/* We are not upadting the <Playlist> but rather swapping out <Playlist> instances */}
                {index}/{playlistItems.length}
              </p>
            </>
          )}
        </div>

        <div className="playlist-preview">
          {playlistItems.map((item, itemIdx) => {
            const isHighlighted =
              type !== "recommended" && itemIdx + 1 === index;
            return (
              <ItemCard
                ref={isHighlighted ? highlightedRef : null}
                object={item}
                key={item.id + itemIdx}
                is_highlighted={isHighlighted}
                handleClick={
                  type !== "recommended"
                    ? () => navigateToItem(itemIdx + 1)
                    : undefined
                }
              />
            );
          })}
        </div>
      </div>
    );
  },
);

export default Playlist;
