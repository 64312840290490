import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function SitemapRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    window.location.href = `${process.env.REACT_APP_API_URL}/sitemap.xml`;
  }, [navigate]);

  return null;
}

export default SitemapRedirect;
