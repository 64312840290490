import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";

import { useFeedCardObservers } from "../../hooks/useFeedCardObservers";
import useWindowWidth from "../../hooks/useWindowWidth";
import useWindowHeight from "../../hooks/useWindowHeight";

import FeedCard from "../../components/FeedComponents/FeedCard";
import InfoCard from "../../components/FeedComponents/InfoCard";

import chevronDown from "../../assets/chevron-down.svg";
import chevronUp from "../../assets/chevron-up.svg";
import "./styles.scss";

const FeedContainer = ({
  data,
  type,
  isMyTab,
  isPrivatePage,
  isEmpty,
  isAuthCard,
  playlistTitle,
  cardRefs,
  totalNumOfCards,
  currentVideoIndex = 0,
}) => {
  /*
    Feed container is a component used to display a list of cards to the user.
    It's used to display Feed, Playlist Page, Video Page. Also, it's embedded into My Playlists tab and VideoPreview.
    It's mostly focused on the frontend part of displaying cards correctly.

    Props: 
    - data: array - list of videos to display (can be extanded to store not only videos)
    - type: string - used to determine the type of the page (Feed, Playlist, Video, Card)
    - isMyTab: boolean - used to display a specific style for the My Tab
    - isPrivatePage: boolean - used to display a specific style for the Private Page
    - isEmpty: boolean - used to display a specific style for the Empty Page
    - isAuthCard: boolean - used to display a specific style for the Auth Card
  */

  // Variables related to window dimension and responsiveness
  const windowWidth = useWindowWidth();
  const windowHeight = useWindowHeight();
  const [deviceType, setDeviceType] = useState("desktop");

  // Dispatch store variable to override and show modal
  const showModal = useSelector((state) => state.global.showModal);

  // State variables to control detail mode. Not put in FeedCard in order persist mode across scrolling
  const [isDetailMode, setIsDetailMode] = useState(false);
  const [selectedOption, setSelectedOption] = useState("description");

  const feedContainerRef = useRef(null);

  // State variable that determines visibility. Permeates to all children FeedCard, Playlist, Video, Card
  const [currFeedCardIndex, setCurrFeedCardIndex] = useState(0);
  const currFeedCardIndexRef = useRef(0); // just for logging purpose in useFeedCardObserver
  const isObserverPausedRef = useRef(false); // pauses useFeedCardObservers when navigating to far items

  useFeedCardObservers(
    cardRefs,
    setCurrFeedCardIndex,
    currFeedCardIndexRef,
    isObserverPausedRef,
  );

  useEffect(() => {
    if (windowWidth > 1440) {
      setDeviceType("desktop");
    } else if (windowWidth > 1280) {
      setDeviceType("macbook-pro");
    } else if (windowWidth > 770) {
      setDeviceType("macbook-air");
    } else {
      setDeviceType("mobile");
    }

    if (windowHeight < 700) {
      setDeviceType("mobile");
    }
  }, [windowWidth, windowHeight]);

  let isThrottled = false;
  const handleChevron = (newIndex) => {
    if (isThrottled) return;

    // Scroll the FeedCard div ref into view
    if (cardRefs.current[newIndex - 1].current) {
      cardRefs.current[newIndex - 1].current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      setCurrFeedCardIndex(newIndex);

      isThrottled = true;

      setTimeout(() => {
        isThrottled = false;
      }, 500);
    }
  };

  useEffect(() => {
    if (currentVideoIndex !== 0) {
      navigateToItem(currentVideoIndex, 1);
    }
  }, [currentVideoIndex]);

  // Function that permeates to FeedCard, Playlist, ItemCard for navigation in Playlist sidebar
  const navigateToItem = (newIndex, nowIndex) => {
    // Temporarily disable observers since the next item can be far up/down the feed
    isObserverPausedRef.current = true;
    handleChevron(newIndex);
    setCurrFeedCardIndex(newIndex); // Hard code the new index instead of relying on FeedCardObserver

    setTimeout(() => {
      isObserverPausedRef.current = false; // Re-enable observers
    }, 1000);
  };

  const handleGlobalKeyDown = (event) => {
    if (showModal) {
      return;
    }

    if (
      (event.target.classList.contains("tiptap") &&
        event.target.classList.contains("ProseMirror")) ||
      event.target.classList.contains("text-input-component-input")
    ) {
      return;
    }
    if (event.key === "ArrowDown") {
      console.log("ArrowDown: going to index", currFeedCardIndex + 1);
    } else if (event.key === "ArrowUp") {
      console.log("ArrowUp: going to index", currFeedCardIndex - 1);
    }
    if (event.key === "ArrowDown" && currFeedCardIndex + 1 <= totalNumOfCards) {
      event.preventDefault();
      handleChevron(currFeedCardIndex + 1);
    } else if (event.key === "ArrowUp" && currFeedCardIndex - 1 > 0) {
      event.preventDefault();
      handleChevron(currFeedCardIndex - 1);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleGlobalKeyDown);

    return () => {
      window.removeEventListener("keydown", handleGlobalKeyDown);
    };
  }, [isDetailMode, showModal, currFeedCardIndex]);

  if (isPrivatePage) {
    return (
      <div className="feed-container">
        <div className="feed-container-card">
          <InfoCard type="privatePage" />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div
        ref={feedContainerRef}
        className={`feed-container hidden-scrollbar ${isMyTab ? " my-tab" : ""}${isDetailMode && deviceType !== "mobile" ? " focus-mode" : ""}${data?.length <= 1 ? " one-card" : ""}`}
      >
        <div className="feed-container-card">
          <InfoCard type="noVideos" />
        </div>
      </div>
    );
  }

  return (
    <div
      ref={feedContainerRef}
      className={`feed-container hidden-scrollbar${isMyTab ? " my-tab" : ""}${isDetailMode && deviceType !== "mobile" ? " focus-mode" : ""}${data?.length <= 1 ? " one-card" : ""}${type === "video-preview" ? " video-preview" : ""}`}
    >
      {data?.map((video, index) => (
        <div
          className={`feed-container-card${type === "video-preview" ? " video-preview-card" : ""}`}
          key={video.id + index}
          ref={cardRefs.current[index]}
        >
          <FeedCard
            type={video?.card_type !== "video" ? "card" : type}
            data={data}
            video={video}
            deviceType={deviceType}
            isMyTab={isMyTab}
            isDetailMode={isDetailMode && deviceType !== "mobile"}
            setIsDetailMode={setIsDetailMode}
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            navigateToItem={navigateToItem}
            playlistTitle={playlistTitle}
            currFeedCardIndex={currFeedCardIndex} // Index of the current visible FeedCard
            index={index + 1} // Index of this FeedCard, isVisible = (currFeedCardIndex === index)
          />
        </div>
      ))}
      {isAuthCard && (
        <div
          className="feed-container-card-end"
          ref={cardRefs.current[data.length]}
        >
          <InfoCard
            type="auth"
            currFeedCardIndex={currFeedCardIndex}
            index={totalNumOfCards}
          />
        </div>
      )}
      <div
        className={`feed-navigation ${isDetailMode && deviceType !== "mobile" ? `focus-mode ${deviceType}` : ""}`}
      >
        <div className={`chevron-container`}>
          {currFeedCardIndex != 1 && deviceType !== "mobile" ? (
            <button onClick={() => handleChevron(currFeedCardIndex - 1)}>
              <img src={chevronUp} alt="Scroll Up" />
            </button>
          ) : null}
        </div>
        <div className="chevron-container">
          {currFeedCardIndex !== totalNumOfCards && deviceType !== "mobile" ? (
            <button onClick={() => handleChevron(currFeedCardIndex + 1)}>
              <img src={chevronDown} alt="Scroll down" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default FeedContainer;
