import React, { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { showSignup } from "../../actions/global.action";
import { useObserver } from "../../hooks/useObserver";
import "./styles.scss";

function InfoCard({ type, currFeedCardIndex, index }) {
  /*
    Info card used to display messages to the user.
    type: 'auth' - displays buttons to sign up or sign in
    type: 'noVideos' - displays message that there are no videos in the playlist
    type: 'privatePage' - displays message that the page doesn't exist
  */
  const cardRef = useRef();
  const isVisible = currFeedCardIndex == index;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isVisible && type === "auth") {
      dispatch(showSignup());
    }
  }, [isVisible, dispatch]);

  return (
    <div className="infocard-container">
      <div
        className={`infocard ${type === "auth" ? "auth-card" : ""}`}
        ref={cardRef}
      >
        {type === "noVideos" && <h3>No videos in the playlist</h3>}
        {type === "privatePage" && <h3>{`The page doesn't exist`}</h3>}
      </div>
    </div>
  );
}

export default InfoCard;
