import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import authService from "../services/auth.service.ts";
import { Cookie } from "../utils/cookie.ts";
import { AUTH_TOKEN } from "../utils/enum.ts";
import {
  AUTHENTICATED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_REFRESH_SUCCESS,
} from "../actions/types";
import { useNavigate } from "react-router-dom";
import { load_user } from "../actions/auth.js";
import { jwtDecode, InvalidTokenError } from "jwt-decode";

const PrivatePage = ({ children, guestAccessible = false }) => {
  /* 
    Determines logic for pages that shouldn't be accessed by non-authenticated users.
  */

  // Access the isAuthenticated part of the state
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const requestCheckJwtToken = async () => {
    checkFlow: try {
      const accessToken =
        Cookie.Get(AUTH_TOKEN.ACCESS_TOKEN) || localStorage.getItem("access");
      const refreshToken =
        Cookie.Get(AUTH_TOKEN.REFRESH_TOKEN) || localStorage.getItem("refresh");

      // Decode token client side: throws InvalidTokenError if wrong form or empty
      // Does not verify on server-side against Auth0 public key
      const decoded = jwtDecode(accessToken);
      const isExpired = decoded.exp < Date.now() / 1000;

      if (!isExpired && accessToken) {
        console.log("Access token not expired. Verifying on server.");
        const response = await authService.checkJwtToken(accessToken); // verify on server-side
        dispatch({ type: AUTHENTICATED_SUCCESS });
        dispatch(load_user());
        break checkFlow;
      }
      if (isExpired && refreshToken) {
        console.log("Access token expired. Refreshing on server");
        const response = await authService.refreshToken(refreshToken); // request new token on server-side
        if (response.access_token) {
          dispatch({
            type: AUTHENTICATED_REFRESH_SUCCESS,
            payload: {
              access: response.access_token, // set new access token
            },
          });
          dispatch(load_user());
          break checkFlow;
        }
      }

      // Catch all other cases, e.g. access token expired but no refresh token
      !guestAccessible && navigate("/login");
      dispatch({ type: AUTHENTICATED_FAIL });
    } catch (e) {
      if (e instanceof InvalidTokenError) {
        console.log(
          "Authentication Failed: Access Token is empty or malformed.",
        );
      } else {
        console.log(
          "Authentication Failed: Access token is well-formed but invalid or refresh token expired.",
        );
      }
      !guestAccessible && navigate("/login"); // nav to login if page is not guest-accessible,
      dispatch({ type: AUTHENTICATED_FAIL });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      requestCheckJwtToken();
    }
  }, []);

  return loading ? null : children;
};

export default PrivatePage;
