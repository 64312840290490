import { playlistService } from "../services/playlist.service.ts";
import { userService } from "../services/user.service.ts";
import {
  FETCH_PLAYLISTS_REQUEST,
  FETCH_PLAYLISTS_SUCCESS,
  FETCH_PLAYLISTS_FAILURE,
  CREATE_PLAYLIST_SUCCESS,
  CREATE_PLAYLIST_FAILURE,
  SET_CURRENT_PLAYLIST,
  SET_PLAYLISTS,
  SET_WATCHING_PLAYLIST,
  UPDATE_PLAYLIST_NAME,
  UPDATE_PLAYLIST_VISIBILITY,
  UPDATE_PLAYLIST_TO_PREVIEW,
  UPDATE_PLAYLIST_TO_PREVIEW_FAILURE,
} from "./types";

const fetchPlaylistsRequest = () => {
  return {
    type: FETCH_PLAYLISTS_REQUEST,
  };
};

const fetchPlaylistsSuccess = (playlists, updateLeftPanel = true) => {
  return {
    type: FETCH_PLAYLISTS_SUCCESS,
    payload: playlists,
    updateLeftPanel,
  };
};

const fetchPlaylistsFailure = (error) => {
  return {
    type: FETCH_PLAYLISTS_FAILURE,
    payload: error,
  };
};

export const fetchPlaylists = (updateLeftPanel = true) => {
  return (dispatch) => {
    dispatch(fetchPlaylistsRequest());
    userService
      .getUserPlaylists()
      .then((response) => {
        dispatch(fetchPlaylistsSuccess(response, updateLeftPanel));
      })
      .catch((error) => {
        dispatch(fetchPlaylistsFailure(error.message));
      });
  };
};

export const createPlaylistSuccess = (playlist) => {
  return {
    type: CREATE_PLAYLIST_SUCCESS,
    payload: playlist,
  };
};

export const createPlaylistFailure = (error) => {
  return {
    type: CREATE_PLAYLIST_FAILURE,
    payload: error,
  };
};

export const createPlaylist = (
  playlistTitle,
  visibility,
  description,
  videos,
  ai_generated = false,
) => {
  return (dispatch) => {
    playlistService
      .createPlaylist(
        playlistTitle,
        visibility,
        description,
        videos,
        ai_generated,
      )
      .then((response) => {
        dispatch(createPlaylistSuccess(response));
        dispatch(fetchPlaylists());
      })
      .catch((error) => {
        dispatch(createPlaylistFailure(error.message));
      });
  };
};

export const setCurrentPlaylist = (playlist) => {
  return (dispatch) => {
    dispatch({
      type: SET_CURRENT_PLAYLIST,
      payload: playlist,
    });

    if (playlist && playlist.object_id) {
      dispatch(updatePlaylistToPreview(playlist.object_id));
    }
  };
};

export const setPlaylists = (playlists) => ({
  type: SET_PLAYLISTS,
  payload: playlists,
});

export const setWatchingPlaylist = (watching) => ({
  type: SET_WATCHING_PLAYLIST,
  payload: watching,
});

export const updatePlaylistName = (name) => ({
  type: UPDATE_PLAYLIST_NAME,
  payload: name,
});

export const updatePlaylistVisibility = (visibility) => ({
  type: UPDATE_PLAYLIST_VISIBILITY,
  payload: visibility,
});

export const updatePlaylistStructure = (
  items,
  currentPlaylist,
  updateState = true,
) => {
  return (dispatch) => {
    playlistService
      .updatePlaylistStructure(
        items,
        currentPlaylist && (currentPlaylist["playlist_item_id"] || 0),
      )
      .then(() => {
        if (updateState) {
          dispatch(updatePlaylistToPreview(currentPlaylist?.object_id));
        }
      });
  };
};

export const updatePlaylistToPreviewSuccess = (playlist) => ({
  type: UPDATE_PLAYLIST_TO_PREVIEW,
  payload: playlist,
});

export const updatePlaylistToPreviewFailure = (error) => ({
  type: UPDATE_PLAYLIST_TO_PREVIEW_FAILURE,
  payload: error,
});

export const updatePlaylistToPreview = (objectId) => {
  return (dispatch) => {
    if (!objectId) {
      return;
    }

    playlistService
      .getPlaylist(objectId)
      .then((response) => {
        dispatch(updatePlaylistToPreviewSuccess(response));
      })
      .catch((error) => {
        dispatch(updatePlaylistToPreviewFailure(error.message));
      });
  };
};
