import React, { useState, useRef, useEffect } from "react";
import DropdownMenuPlaylistComponent from "../DropdownMenuPlaylistComponent/DropdownMenuPlaylistComponent";
import CrossSign from "../../assets/cross-sign-tag.svg";
import "./styles.scss";
import { videoService } from "../../services/video.service.ts";
import TooltipComponent from "../TooltipComponent/TooltipComponent.js";

const MutlipleComponent = ({
  label = "Tag",
  icon = true,
  handleTagChange,
  placeholderText = "Enter your tag",
  initialInput = [],
  categorySelector = false,
  limit = Infinity,
  isLowerCased = false,
}) => {
  /*
    one of the components used to getting tags
    callback prop: handleTagChange - function that will be called when tags are changed
    limit: the maximum number of tags that can be entered (Infinity by default)
    */
  const [tags, setTags] = useState([]);
  const [input, setInput] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [suggestionsVisible, setSuggestionsVisible] = useState(false);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await videoService.getCategories();
        const sortedCategories = response
          .map((category) => category.name)
          .sort((a, b) => a.localeCompare(b));
        setCategoryList(sortedCategories);
        setFilteredCategories(sortedCategories);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    if (initialInput.length > 0) {
      setTags(initialInput);
    }
  }, [initialInput]);

  useEffect(() => {
    handleTagChange(tags);
    setSuggestionsVisible(false);
  }, [tags]);

  const handleCategoryChange = (category) => {
    if (!Array.isArray(category)) {
      if (categoryList.includes(category)) {
        if (!tags.includes(category) && tags.length < limit) {
          setTags([...tags, category]);
        } else {
          setTags(tags.filter((tag) => tag !== category));
        }
      }
      setInput("");
      setFilteredCategories(categoryList);
    }
  };

  const handleInputChange = (e) => {
    let input = e.target.value;
    setSuggestionsVisible(true);
    if (categorySelector) {
      input = input.replace(/[!@#$%^&*()+=[\]{}|\\:;"'<>,.?]/g, "");
    } else {
      input = input.replace(/[!@#$%^&*()+=[\]{}|\\:;"'<>,.?/ ]/g, "").trim();
      if (isLowerCased) {
        input = input.toLowerCase();
      }
    }
    if (tags.length >= limit) {
      input = "";
    }
    setInput(input);
    setFilteredCategories(
      categoryList.filter((category) =>
        category.toLowerCase().includes(input.toLowerCase()),
      ),
    );
  };

  const handleInputKeyDown = (e) => {
    e.stopPropagation();

    let checkForCategories = true;
    let input = e.target.value;
    if (categorySelector) {
      input = input.replace(/[!@#$%^&*()+=[\]{}|\\:;"'<>,.?]/g, "");
      input = input.charAt(0).toUpperCase() + input.slice(1);
      checkForCategories =
        categoryList.includes(input) && !tags.includes(input);
    } else {
      input = input.replace(/[!@#$%^&*()+=[\]{}|\\:;"'<>,.?/ ]/g, "").trim();
      if (isLowerCased) {
        input = input.toLowerCase();
      }
    }
    if (tags.length >= limit) {
      input = "";
    }
    if (
      (e.key === "Enter" ||
        e.key === "," ||
        (!categorySelector && e.key === " ")) &&
      input &&
      checkForCategories
    ) {
      e.preventDefault();
      if (!tags.includes(input)) {
        setTags([...tags, input]);
      }
      setInput("");
      setFilteredCategories(categoryList);
    } else if (e.key === "Backspace" && !input) {
      setTags(tags.slice(0, -1));
    }
  };

  const removeTag = (index) => {
    setTags(tags.filter((tag, i) => i !== index));
  };

  const handleContainerClick = () => {
    inputRef.current.focus();
  };

  const toolTipText =
    label === "Tag"
      ? "Keywords or phrases that describe the video."
      : label === "Interest"
        ? "You can add up to 10 interests."
        : "Topic category of the video.";

  return (
    <div className="tag-input-component">
      <div className="tag-input-label">
        {label}
        {icon && <TooltipComponent tipText={toolTipText} />}
      </div>
      <div
        className="tag-input-container"
        onClick={handleContainerClick}
        ref={containerRef}
      >
        {tags.map((tag, index) => (
          <div key={index} className="tag-item">
            {categorySelector
              ? typeof tag === "object"
                ? tag.name
                : "#" + tag
              : tag}
            <button
              onClick={() => removeTag(index)}
              className="tag-remove-button"
            >
              <img
                src={CrossSign}
                alt="remove"
                className="tag-remove-button-img"
              />
            </button>
          </div>
        ))}
        <input
          type="text"
          ref={inputRef}
          value={input}
          onFocus={() => {
            containerRef.current.style.borderColor = "#0D8EEC";
            setSuggestionsVisible(true);
          }}
          onBlur={() => {
            containerRef.current.style.borderColor = "#C7C7CC";
          }}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder={tags.length === 0 ? placeholderText : ""}
          className="tag-input-field"
          size={input.length ? input.length + 1 : 1}
        />
      </div>
      {categorySelector && suggestionsVisible && (
        <div className="tag-suggestions">
          <DropdownMenuPlaylistComponent
            isRightAlignedProp={false}
            type="category"
            chosenOption={tags}
            handleChange={handleCategoryChange}
            handleClose={() => setSuggestionsVisible(false)}
            categories={filteredCategories}
          />
        </div>
      )}
    </div>
  );
};

export default MutlipleComponent;
