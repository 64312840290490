import React from "react";
import playlistIcon from "../../assets/playlist-white.svg";
import { cardMapping } from "../../utils/constants";
import "./styles.scss";

const PlaylistThumbnail = ({
  noPhoto = true,
  playlist,
  clickHandled = false,
}) => {
  /*
  Thumbnail for a playlist.
  */

  const itemsCount = playlist?.card_count
    ? playlist?.video_count + playlist?.card_count
    : playlist?.video_count;

  let isPhoto = false;
  let url = null;
  let type = null;

  if (!noPhoto) {
    if (playlist.video_count > 0) {
      isPhoto = true;
      if (playlist.thumbnail_url) {
        url = playlist.thumbnail_url;
        type = playlist.thumbnail_type;
      } else {
        type = playlist.card_type;
      }
    }
  }

  const handleClick = () => {
    if (playlist.video_count > 0 && !clickHandled) {
      window.open(`/playlist/${playlist.object_id}`, "_blank");
    }
  };

  return (
    <div
      className={`playlist-image-container-container ${playlist?.video_count > 0 ? "playlist-image-container-exist" : ""}`}
      onClick={handleClick}
    >
      <div className="playlist-background"></div>
      <div className="playlist-image-container">
        {isPhoto && ["landscape", "portrait"].includes(type) ? (
          <img
            className="playlist-image"
            src={url}
            style={{
              objectFit: type === "landscape" ? "cover" : "contain",
            }}
          ></img>
        ) : isPhoto ? (
          <div className="card-thumbnail">
            <img src={cardMapping[type]?.icon} />
            <span>{cardMapping[type]?.text}</span>
          </div>
        ) : (
          <div className="playlist-image-placeholder"></div>
        )}
        <div className="playlist-info-bar">
          <img src={playlistIcon}></img>
          {(!noPhoto && itemsCount) || "0"} Item
          {(!noPhoto && itemsCount) !== 1 && "s"}
        </div>
      </div>
    </div>
  );
};

export default PlaylistThumbnail;
