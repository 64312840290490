import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FilterList from "../FilterList/FilterList";
import ListVideoComponent from "../ListVideoComponent/ListVideoComponent";
import Spinner from "../Spinner/Spinner";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";
import { setFavorites } from "../../actions/data";

const Favorites = () => {
  /*
  Creates a page for Favorite videos
  Alligned with latest Figma design with certain changes (May 24)
  */

  const [selectedCategories, setSelectedCategories] = useState(["All"]);
  const dispatch = useDispatch();
  const favoriteVideos = useSelector((state) => state.data.favorites);
  const updateFavorites = useSelector((state) => state.data.updateFavorites);
  const userCategories = useSelector((state) => state.data.favoritesCategories);
  const [loading, setLoading] = useState(favoriteVideos.length === 0);

  const fetchUserFavorites = async () => {
    try {
      const response = await userService.getUserFavorites();

      const categories = [
        "All",
        ...new Set(
          response.flatMap((item) =>
            item.categories.map((category) => category.name),
          ),
        ),
      ].sort();

      dispatch(setFavorites(response, categories));
    } catch (error) {
      console.error("Error fetching user favorites:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (updateFavorites) {
      fetchUserFavorites();
    } else {
      setLoading(false);
    }
  }, [dispatch, updateFavorites]);

  const filterVideos = (category) => {
    return favoriteVideos.filter((video) => {
      return (
        video.categories &&
        video.categories.some(
          (videoCategory) => videoCategory.name === category,
        )
      );
    });
  };

  const handleClick = (category) => {
    setSelectedCategories([category]);
  };

  return (
    <div className="favorites">
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100vh - 100px)",
          }}
        >
          <Spinner />
        </div>
      ) : (
        <div>
          <FilterList
            categories={userCategories}
            selectedCategories={selectedCategories}
            handleClick={handleClick}
          />
          <div className="favorites-lists">
            {userCategories.map((category, index) => {
              const videosToShow = filterVideos(category);
              return (
                videosToShow.length > 0 &&
                (selectedCategories.includes("All") ||
                  selectedCategories.includes(category)) && (
                  <ListVideoComponent
                    key={index}
                    items={videosToShow}
                    heading={category}
                  />
                )
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Favorites;
