import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setWatchHistory } from "../../actions/data";
import { isToday, isThisWeek, isThisMonth } from "date-fns";
import ListVideoComponent from "../ListVideoComponent/ListVideoComponent";
import Spinner from "../Spinner/Spinner";
import "./styles.scss";
import { userService } from "../../services/user.service.ts";

const WatchHistory = () => {
  /*
    Subpage in Dashboard > Watch History tab, where a user can see a list of all watched videos.
  */

  const dispatch = useDispatch();
  const watchHistory = useSelector((state) => {
    const videos = state.data.watchHistory;
    return {
      today: videos
        .filter((video) => isToday(new Date(video.timestamp)))
        .map((entry) => entry.video),
      thisWeek: videos
        .filter(
          (video) =>
            isThisWeek(new Date(video.timestamp), { weekStartsOn: 1 }) &&
            !isToday(new Date(video.timestamp)),
        )
        .map((entry) => entry.video),
      pastMonth: videos
        .filter(
          (video) =>
            isThisMonth(new Date(video.timestamp)) &&
            !isThisWeek(new Date(video.timestamp), { weekStartsOn: 1 }),
        )
        .map((entry) => entry.video),
    };
  });
  const [loading, setLoading] = useState(
    watchHistory.today.length === 0 &&
      watchHistory.thisWeek.length === 0 &&
      watchHistory.pastMonth.length === 0,
  );
  const updateWatchHistory = useSelector(
    (state) => state.data.updateWatchHistory,
  );

  const fetchWatchHistory = async () => {
    try {
      const response = await userService.getWatchHistory();
      dispatch(setWatchHistory(response));
    } catch (error) {
      console.error("Error fetching user watch history:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (updateWatchHistory) {
      fetchWatchHistory();
    } else setLoading(false);
  }, [dispatch, updateWatchHistory, loading]);

  return (
    <div className="watch-history">
      <div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 100px)",
            }}
          >
            <Spinner />
          </div>
        ) : (
          <div>
            {watchHistory.today.length ? (
              <ListVideoComponent items={watchHistory.today} heading="Today" />
            ) : null}
            {watchHistory.thisWeek.length ? (
              <ListVideoComponent
                items={watchHistory.thisWeek}
                heading="This week"
              />
            ) : null}
            {watchHistory.pastMonth.length ? (
              <ListVideoComponent
                items={watchHistory.pastMonth}
                heading="Past month"
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default WatchHistory;
