import React from "react";
import ColorComponent from "../../components/ColorComponent/ColorComponent";
import { cardColors } from "../../utils/constants";
import "./styles.scss";

const ColorPickerComponent = ({ cardInfo, setCardInfo }) => {
  return (
    <div className="colors">
      {cardColors.map((color) => (
        <ColorComponent
          key={color}
          color={color}
          chosen={cardInfo.color === color}
          setChosen={() => {
            setCardInfo((prevCardInfo) => ({
              ...prevCardInfo,
              color: color,
            }));
          }}
        />
      ))}
    </div>
  );
};

export default ColorPickerComponent;
