import {
  FETCH_PLAYLISTS_REQUEST,
  FETCH_PLAYLISTS_SUCCESS,
  FETCH_PLAYLISTS_FAILURE,
  CREATE_PLAYLIST_SUCCESS,
  CREATE_PLAYLIST_FAILURE,
  SET_CURRENT_PLAYLIST,
  SET_PLAYLISTS,
  SET_WATCHING_PLAYLIST,
  UPDATE_PLAYLIST_NAME,
  UPDATE_PLAYLIST_VISIBILITY,
  UPDATE_PLAYLIST_TO_PREVIEW,
  UPDATE_PLAYLIST_TO_PREVIEW_FAILURE,
} from "../actions/types";
import { MAX_NEST_LVL } from "../utils/constants";

const initialState = {
  loading: false,
  playlists: [],
  playlistsLeftPanel: [],
  watchingPlaylist: false,
  currentPlaylist: null,
  error: "",
  isCreatingPlaylist: false,
  currentPlaylistName: "",
  currentPlaylistVisibility: "",
  playlistToPreview: null,
};

const playlistReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAYLISTS_REQUEST:
      console.log("FETCH_PLAYLISTS_REQUEST");
      return {
        ...state,
        loading: true,
      };
    case FETCH_PLAYLISTS_SUCCESS:
      const { payload: playlistData, updateLeftPanel = true } = action;

      const transformItem = (item, nestLvl = 0, parentId = null) => {
        if (nestLvl === MAX_NEST_LVL) {
          return {
            ...item,
            isVideo: !!item?.video_url,
            parentId,
            isRoot: nestLvl === 0,
            nestLvl,
            children: item?.video_url ? [] : undefined,
          };
        }

        return {
          ...item,
          isVideo: !!item?.video_url,
          parentId,
          isRoot: nestLvl === 0,
          nestLvl,
          children: item?.children
            ?.map((child) => {
              if (nestLvl + 1 === MAX_NEST_LVL && !child?.video_url) {
                return null;
              }
              return transformItem(child, nestLvl + 1, item.object_id);
            })
            .filter(Boolean),
        };
      };

      const newPlaylist = playlistData.map((item) => transformItem(item));

      const updatedCurrentPlaylist = state.currentPlaylist
        ? newPlaylist.find(
            (item) => item.object_id === state.currentPlaylist.object_id,
          ) || state.currentPlaylist
        : null;

      return {
        ...state,
        loading: false,
        playlists: newPlaylist,
        playlistsLeftPanel: updateLeftPanel
          ? newPlaylist
          : state.playlistsLeftPanel,
        currentPlaylist: updatedCurrentPlaylist,
        error: "",
      };

    case FETCH_PLAYLISTS_FAILURE:
      return {
        ...state,
        loading: false,
        playlists: [],
        error: action.payload,
      };
    case CREATE_PLAYLIST_SUCCESS:
      return {
        ...state,
        playlists: [...state.playlists, action.payload].map((item) => ({
          ...item,
          isRoot: true,
        })),
        error: null,
      };
    case CREATE_PLAYLIST_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_CURRENT_PLAYLIST:
      return {
        ...state,
        currentPlaylist: action.payload,
      };
    case SET_PLAYLISTS:
      return {
        ...state,
        playlists: action.payload.map((item) => ({ ...item, isRoot: true })),
      };
    case SET_WATCHING_PLAYLIST:
      return {
        ...state,
        watchingPlaylist: action.payload,
      };
    case UPDATE_PLAYLIST_NAME:
      return {
        ...state,
        currentPlaylistName: action.payload,
      };
    case UPDATE_PLAYLIST_VISIBILITY:
      return {
        ...state,
        currentPlaylist: state.currentPlaylist && {
          ...state.currentPlaylist,
          visibility: action.payload,
        },
        currentPlaylistVisibility: action.payload,
      };
    case UPDATE_PLAYLIST_TO_PREVIEW:
      if (state.currentPlaylist?.object_id === action.payload.id) {
        return {
          ...state,
          playlistToPreview: action.payload,
        };
      }
      return state;
    case UPDATE_PLAYLIST_TO_PREVIEW_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default playlistReducer;
