import {
  ADD_LIKE,
  REMOVE_LIKE,
  SET_DATA,
  SET_USER_VIDEO_DATA,
  UPDATE_USER_VIDEO_DATA,
  SUBSCRIBE,
  UNSUBSCRIBE,
  UPDATE_NOTE_CONTENT,
  SET_WATCH_HISTORY,
  UPDATE_WATCH_HISTORY,
  SET_FAVORITES,
  UPDATE_FAVORITES,
  SET_NOTE_LIST,
  UPDATE_NOTE_LIST,
} from "../actions/types";

const initialState = {
  data: [],
  userVideoData: [],
  updateUserVideoData: true,
  watchHistory: [],
  updateWatchHistory: true,
  favorites: [],
  updateFavorites: true,
  favoritesCategories: [],
  noteList: [],
  updateNoteList: true,
};

export const dataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DATA:
      return {
        ...state,
        updateWatchHistory: true,
        data: action.payload,
      };
    case SET_USER_VIDEO_DATA:
      return {
        ...state,
        updateUserVideoData: false,
        userVideoData: action.payload,
      };
    case UPDATE_USER_VIDEO_DATA:
      return {
        ...state,
        updateUserVideoData: true,
      };
    case SET_WATCH_HISTORY:
      return {
        ...state,
        updateWatchHistory: false,
        watchHistory: action.payload,
      };
    case UPDATE_WATCH_HISTORY:
      return {
        ...state,
        updateWatchHistory: true,
      };
    case SET_NOTE_LIST:
      return {
        ...state,
        updateNoteList: false,
        noteList: action.payload,
      };
    case UPDATE_NOTE_LIST:
      return {
        ...state,
        updateNoteList: true,
      };
    case SET_FAVORITES:
      return {
        ...state,
        updateFavorites: false,
        favorites: action.payload.data,
        favoritesCategories: action.payload.categories,
      };
    case UPDATE_FAVORITES:
      return {
        ...state,
        updateFavorites: true,
      };
    case UPDATE_NOTE_CONTENT:
      return {
        ...state,
        updateNoteList: true,
        userVideoData: state.userVideoData.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              notes: [{ ...item.notes[0], content: action.payload.note }],
            };
          }
          return item;
        }),
        data: state.data.map((item) => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              notes: [{ ...item.notes[0], content: action.payload.note }],
            };
          }
          return item;
        }),
      };
    case ADD_LIKE:
      return {
        ...state,
        userVideoData: state.userVideoData.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              is_liked: true,
              likes_count: item.likes_count + 1,
            };
          }
          return item;
        }),
        data: state.data.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              is_liked: true,
              likes_count: item.likes_count + 1,
            };
          }
          return item;
        }),
      };
    case REMOVE_LIKE:
      return {
        ...state,
        userVideoData: state.userVideoData.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              is_liked: false,
              likes_count: Math.max(item.likes_count - 1, 0),
            };
          }
          return item;
        }),
        data: state.data.map((item) => {
          if (item.id === action.payload) {
            return {
              ...item,
              is_liked: false,
              likes_count: Math.max(item.likes_count - 1, 0),
            };
          }
          return item;
        }),
      };
    case SUBSCRIBE:
      return {
        ...state,
        isSubscribed: true,
      };
    case UNSUBSCRIBE:
      return {
        ...state,
        isSubscribed: false,
      };
    default:
      return state;
  }
};
